const reportCols = [
  {
    label: "达人信息",
    prop: "name",
    align: "left",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "投流消耗($)",
    prop: "input_cost",
    align: "center",
    width: 150,
    tooltip: "投流的总消耗金额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "达人薪资($)",
    prop: "per_diem",
    align: "center",
    width: 150,
    tooltip: "达人在这个周期内的工资",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估MCN佣金($)",
    prop: "predict_mcn_commission",
    align: "center",
    width: 200,
    tooltip: "预估 MCN 佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "佣金业务净毛利($)",
    prop: "commission_service_profit",
    align: "center",
    width: 200,
    tooltip: "佣金业务净毛利 = 预估MCN佣金-投流消耗-达人薪资",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估TAP佣金($)",
    prop: "predict_tap_commission",
    align: "center",
    width: 200,
    tooltip: "预计联盟合作伙伴获得的佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "总毛利($)",
    prop: "total_gross_profit",
    align: "center",
    width: 200,
    tooltip: "佣金业务净毛利+预估TAP佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "利润目标($)",
    prop: "profit_target",
    align: "center",
    width: 200,
    tooltip: "达人的利润目标",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP GMV占比(%)",
    prop: "tap_gmv_proportion",
    align: "center",
    width: 200,
    tooltip: "TAP 产品 GMV = Affiliate partner-“待打款+已打款”-选取对应周期、对应达人-预估计佣金额 汇总",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "目标 TAP 佣金比(%)",
    prop: "goal_tap_ratio",
    align: "center",
    width: 200,
    tooltip: "（利润目标-总毛利）/（TAP产品GMV）",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
];
export default reportCols;
